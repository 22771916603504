import React, { Fragment, useEffect, useState } from 'react';
import WhatWeAreDoing from '../homepage/WhatWeAreDoing';
import Intro from '../homepage/Intro';
import About from '../homepage/About';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Ad from '../homepage/Ad';

const Landing = ({ auth: { isAuthenticated, loading } }) => {

  return (
    <Fragment>
      {!loading && (
        <Fragment>
          {isAuthenticated ? (
            <Fragment>
              <Redirect to="/dashboard" />
            </Fragment>
          ) : (
            <Fragment>
              <Ad />
              <Intro />
              <WhatWeAreDoing />
              <About />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(Landing);
